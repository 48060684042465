import {
  FC,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  Breadcrumbs,
  Button,
  Heading,
  Input,
  RadioGroup,
  SelectOption,
  SortOption,
  Switch,
  Table,
  useEffectSkipFirst,
  useQueryParams,
  Link,
  StatusElement,
  Tooltip,
  getColor,
} from '@faxi/web-component-library';
import { CalendarField, Icon, NoPermissionsPlaceholder } from 'components';
import {
  JOURNEY_SEARCH_OPTIONS,
  JourneySearchParams,
  PARAMS_PLACEHOLDERS,
  SEARCH_PARAMS,
  STATUS_MAP,
  STATUS_TRANSLATIONS,
  TRANSLATION_KEYS,
} from './constants';
import { JOURNEY_STATUS, Journey, JourneyReport } from 'models/Journey';
import { appUri, COLUMNS_SETTINGS_LABELS, reportsConfig } from 'config';
import { maxLength, numbersOnly } from 'validation/general';
import { useParams } from 'react-router';
import dayjs from 'dayjs';

import AuthContext from 'store/AuthProvider/Auth.context';
import apiJourneys from 'modules/router/api/apiJourneys';
import { dateInUTCFormat } from 'utils';
import { storageService } from 'services';
import { useColumnSettings, useTablePagination } from 'hooks';
import { mapVerifications } from './pages/components/ManualVerificationModal/utils';
import { STORAGE_KEYS } from 'services/storageService';
import PocsTimeTableData from './components/PocsTimeTableData';
import ExcludeModal from './components/ExcludeModal';
import ReportsModal from './components/ReportsModal';
import ManualVerificationModal, {
  VerificationParticipantForm,
} from './pages/components/ManualVerificationModal';

import * as TablePageLayout from '../../components/_layouts/PageLayout.styles';

enum MAP_TYPE {
  'W' = 'Walking',
  'B' = 'Cycling',
  'C' = 'Carpooling',
}

export type TableJourney = Omit<Journey, 'status'> & {
  id_clean: number;
  status: ReactNode;
  email: string;
  vehicle_registration: string;
};

const Journeys: FC = () => {
  const { admin } = useContext(AuthContext);

  const {
    params: { searchParam = SEARCH_PARAMS.organisation_id, hide_test },
    setQueryParam,
    removeQueryParam,
  } = useQueryParams<{
    searchParam: JourneySearchParams;
    hide_test?: 'true';
    search?: string;
  }>();

  const { communityId } = useParams<{
    communityId: string;
  }>();

  const [journeyId, setJourneyId] = useState<number>();

  const [hideTestCommunities, setHideTestCommunities] = useState(!!hide_test);
  const [excludeModal, setExcludeModal] = useState<boolean>(false);
  const [manualVerificationModal, setManualVerificationModal] =
    useState<boolean>(false);

  const [journeyReported, setJourneyReported] = useState<JourneyReport[]>([]);

  const selectedJourney = useRef<TableJourney>();

  const {
    columnBtnRef,
    columnSettingsOpen,
    closeColumnSettings,
    ColumnSettingsButton,
  } = useColumnSettings();

  const communityName = useCallback(
    () => storageService.getItem(STORAGE_KEYS.SELECTED_COMMUNITY),
    []
  );

  const canEdit = useMemo(
    () => !!admin?.permissions.find((p) => p.name === 'journeys_edit'),
    [admin]
  );

  const inputValidations = useMemo(
    () =>
      ({
        user_id: [
          numbersOnly('Only numbers are allowed'),
          maxLength('Maximum number of characters is 10', 10),
        ],
        organisation_id: [
          numbersOnly('Only numbers are allowed'),
          maxLength('Maximum number of characters is 10', 10),
        ],
        journey_id: [
          numbersOnly('Only numbers are allowed'),
          maxLength('Maximum number of characters is 10', 10),
        ],
      } as Record<JourneySearchParams, Array<() => void>>),
    []
  );

  const {
    data: journeysData,
    count,
    totalCount,
    totalPages,
    currentPage,
    search,
    requestError,
    activeColumnSort,
    revalidateData,
    setCount,
    setCurrentPage,
    setSearch,
    setActiveColumnSort,
    setData,
  } = useTablePagination<TableJourney, 'journeys'>({
    itemsKey: 'journeys',
    deps: [hideTestCommunities],
    spinnerParentClass: '.kinto-page',
    initialParams: {
      sortBy: 'id',
      sortDirection: 'desc',
      searchParam,
      page: 1,
    },
    mappingFunction: (values: Array<Journey>) => mapJourneysData(values),
    apiRequest: ({
      per_page,
      currentPage,
      searchString,
      sort_by,
      sort_direction,
    }) => {
      const search = communityId
        ? { param: SEARCH_PARAMS.organisation_id, value: communityId }
        : searchString
        ? { param: searchParam, value: searchString }
        : undefined;
      return apiJourneys.getJourneys({
        per_page,
        page: currentPage,
        searchString: search,
        sort_by,
        sort_direction,
        hide_test: +hideTestCommunities,
      });
    },
  });

  const tableActions = useMemo(
    () => (journey: TableJourney) => {
      const participants = mapVerifications(
        journey.participants
      ) as Array<VerificationParticipantForm>;

      return (
        <TablePageLayout.ActionsContainer>
          <Link
            icon={<Icon name="route" />}
            to={
              communityId
                ? appUri.COMMUNITIES_JOURNEY(communityId, `${journey.id_clean}`)
                : appUri.JOURNEY(`${journey.id_clean}`)
            }
          >
            Journey details
          </Link>
          <Link
            to={
              communityId
                ? appUri.COMMUNITIES_JOURNEY_MAP(
                    communityId,
                    `${journey.id_clean}`
                  )
                : appUri.JOURNEY_MAP(`${journey.id_clean}`)
            }
            icon={<Icon name="map-location-dot" />}
          >
            Map View
          </Link>
          {canEdit &&
            !participants.every((participant) => participant.verified) &&
            !(
              participants.length === 1 && participants[0].type === 'driver'
            ) && (
              <Button
                type="button"
                variant="ghost"
                icon={<Icon name="badge-check" />}
                onClick={() => {
                  setManualVerificationModal(true);
                  selectedJourney.current = journey;
                }}
              >
                Manual verification
              </Button>
            )}
          {canEdit && !journey.uncertain && (
            <Tooltip
              content="Unverified journey can’t be excluded"
              disabled={journey.pocs_type !== '-'}
            >
              <div>
                <Button
                  type="button"
                  variant="delete-ghost"
                  icon={<Icon name="ban" />}
                  onClick={() => {
                    setExcludeModal(true);
                    setJourneyId(journey.id_clean as number);
                  }}
                  disabled={journey.pocs_type === '-'}
                >
                  Exclude
                </Button>
              </div>
            </Tooltip>
          )}
        </TablePageLayout.ActionsContainer>
      );
    },
    [canEdit, communityId]
  );

  const mapJourneysData = useCallback(
    (journeys: Array<Journey>) =>
      journeys.map(
        ({
          organisation_id,
          id,
          user,
          user_id,
          started,
          ended,
          pocs_time,
          pocs_reason,
          pocs_type,
          booking_id,
          number_of_passengers,
          type,
          status,
          start_point,
          end_point,
          direction,
          ticket,
          participants,
          aborting_reason,
          uncertain,
          reported,
        }) =>
          ({
            uncertain,
            participants,
            organisation_id,
            id_clean: id,
            id: (
              <div className="kinto-journeys__table__tooltip">
                <span>{id}</span>
                {uncertain && (
                  <Tooltip
                    placement="top"
                    content="This journey is excluded from stats and reports"
                  >
                    <span className="kinto-journeys__table__tooltip">
                      <Icon name="ban" />
                    </span>
                  </Tooltip>
                )}
              </div>
            ),
            user_id,
            reported: reported ? (
              <Button
                variant="outline"
                className="kinto-journeys__table__journey-report-button"
                onClick={(ev) => {
                  ev.stopPropagation();

                  setJourneyReported(
                    reported.map((report) => ({ ...report, jid: id }))
                  );
                }}
              >
                <Icon
                  color={getColor('--ALERT_ERROR_1_1')}
                  name="circle-exclamation-solid"
                />
                {reported?.[0].answered && (
                  <Icon
                    color={getColor('--PRIMARY_1_1')}
                    name="message-lines"
                  />
                )}
              </Button>
            ) : (
              '-'
            ),
            email: user.user_email,
            vehicle_registration: user.user_vrm || '-',
            started: dateInUTCFormat(started),
            ended: dateInUTCFormat(ended),
            pocs_time: (
              <PocsTimeTableData
                pocsTime={pocs_time as string}
                pocsReason={pocs_reason}
              />
            ),
            pocs_reason,
            pocs_type: pocs_type || '-',
            booking_id: booking_id ? 'Booked' : 'Adhoc',
            number_of_passengers,
            direction: direction === 'AB' ? 'Home To Work' : 'Work To Home',
            start_point: `${start_point?.coordinates[1]}, ${start_point?.coordinates[0]}`,
            end_point: `${end_point?.coordinates[1]}, ${end_point?.coordinates[0]}`,
            type: MAP_TYPE[type as keyof typeof MAP_TYPE],
            ticket: `${ticket || 'No ticket'} `,
            status: (
              <StatusElement
                status={STATUS_MAP[status]}
                enableTooltip={
                  !!aborting_reason ||
                  status === JOURNEY_STATUS.ACTIVE_NO_PASSENGER ||
                  status === JOURNEY_STATUS.FINISHED_NO_PASSENGER
                }
                tooltipText={aborting_reason || 'no passengers'}
              >
                {STATUS_TRANSLATIONS[status]}
              </StatusElement>
            ),
          } as unknown as TableJourney)
      ),
    []
  );

  const handleOnColumnSortJourneys = useCallback(
    (sortOptions: SortOption<TableJourney>) => {
      setActiveColumnSort(sortOptions);

      setCurrentPage(1);
    },
    [setActiveColumnSort, setCurrentPage]
  );

  const handleChangeSearchParam = useCallback(
    (value: string) => {
      setCurrentPage(1);
      setSearch('');
      setQueryParam('searchParam', SEARCH_PARAMS[value as JourneySearchParams]);
    },
    [setCurrentPage, setQueryParam, setSearch]
  );

  useEffectSkipFirst(() => {
    setHideTestCommunities(!!hide_test);
    setCurrentPage(1);
  }, [hide_test]);

  return requestError ? (
    <NoPermissionsPlaceholder />
  ) : (
    <>
      <TablePageLayout.PageLayoutContainer className="kinto-page">
        {communityId && (
          <Breadcrumbs
            className="kinto-page__breadcrumbs"
            crumbs={[
              { text: 'Communities', href: appUri.COMMUNITIES },
              {
                text: `Journeys (${communityName()})`,
                href: '',
              },
            ]}
          />
        )}
        <Heading
          level="1"
          color={getColor('--PRIMARY_1_1')}
          className="kinto-page__header"
        >
          Journeys {communityId && `(${communityName()})`}
        </Heading>

        {!communityId && (
          <>
            <RadioGroup
              className="kinto-page__search-param"
              name="searchParam"
              orientation="row"
              options={JOURNEY_SEARCH_OPTIONS}
              selected={searchParam}
              onChange={handleChangeSearchParam}
            />
            <div className="kinto-page__actions">
              {searchParam !== 'date' ? (
                <Input
                  placeholder={PARAMS_PLACEHOLDERS[searchParam]}
                  value={search}
                  prefixIcon={<Icon name="search" />}
                  validators={inputValidations[searchParam]}
                  {...(search && {
                    suffixIcon: (
                      <Button
                        variant="ghost"
                        aria-label="Delete input search value"
                        onClick={() => setSearch('')}
                        icon={<Icon name="xmark" />}
                      />
                    ),
                  })}
                  onChange={(value, error) => {
                    setSearch(value, error);
                    setCurrentPage(1);
                  }}
                />
              ) : (
                <CalendarField
                  placeholder={PARAMS_PLACEHOLDERS[searchParam]}
                  className="kinto-page__calendar"
                  onSetDate={(date) => {
                    setSearch(date ? date.format('YYYY-MM-DD') : '');
                    setCurrentPage(1);
                  }}
                  leftLimitDate={reportsConfig.range_start}
                  rightLimitDate={dayjs()}
                  openAtToday
                />
              )}

              <Switch
                className="kinto-page__actions__right-side__toggle"
                label="Hide test journeys"
                value={hide_test === 'true'}
                onChange={() =>
                  !hide_test
                    ? setQueryParam('hide_test', 'true')
                    : removeQueryParam('hide_test')
                }
              />

              {journeysData.length !== 0 && <ColumnSettingsButton />}
            </div>
          </>
        )}
        {journeysData.length === 0 ? (
          <div className="kinto-page__empty-state">
            Sorry, there are no results that match your search.
          </div>
        ) : (
          <Table<TableJourney>
            cacheColumns
            tableId="journeys-table"
            className="kinto-journeys__table"
            expandable
            tableData={journeysData}
            breakAtMaxWidth={1400}
            columnSettingsOpen={columnSettingsOpen}
            columnsBtnElement={columnBtnRef.current!}
            columnsModalLabels={COLUMNS_SETTINGS_LABELS}
            excludeColumns={[
              'id_clean',
              'user',
              'participants',
              'pocs_reason',
              'uncertain',
            ]}
            translationKeys={TRANSLATION_KEYS}
            initialSort={activeColumnSort}
            tableActions={tableActions}
            paginationData={{
              currentPage,
              limit: count,
              totalCount,
              totalPages,
            }}
            goToPageInputProps={{ placeholder: 'Go to page' }}
            onColumnSortClicked={handleOnColumnSortJourneys}
            onPageChange={setCurrentPage}
            onColumnsModalClose={closeColumnSettings}
            onLimitChange={(data: SelectOption) => {
              setCurrentPage(1);
              setCount(+data.value);
            }}
            excludeSortColumns={[
              'participants',
              'start_point',
              'end_point',
              'direction',
              'ticket',
              'email',
              'vehicle_registration',
              'booking_id',
            ]}
          />
        )}

        {manualVerificationModal && (
          <ManualVerificationModal
            onClose={() => setManualVerificationModal(false)}
            journey={selectedJourney.current}
            onVerify={setData}
          />
        )}

        {excludeModal && journeyId && (
          <ExcludeModal
            journeyId={journeyId}
            onExclude={revalidateData}
            onClose={() => setExcludeModal(false)}
          />
        )}
        {journeyReported.length !== 0 && (
          <ReportsModal
            reports={journeyReported}
            onClose={() => setJourneyReported([])}
            revalidate={revalidateData}
          />
        )}
      </TablePageLayout.PageLayoutContainer>
    </>
  );
};

export default Journeys;
