import { useCallback, useMemo, useRef, useState } from 'react';
import {
  Breadcrumbs,
  Heading,
  SelectOption,
  SortOption,
  Table,
  getColor,
  useQueryParams,
  StatusElement,
  Switch,
} from '@faxi/web-component-library';

import { uniqueId } from 'lodash';
import { TablePageLayout } from 'components';
import FiltersButton from 'components/_molecules/FiltersButton';
import FiltersModal from 'components/_molecules/FiltersModal';
import { INameExtended } from 'components/Icon';

import { CommunityStatus, UserToCommunity, ConsentType } from 'models';
import { apiUsers } from 'modules';
import { appUri } from 'config';
import { useTablePagination } from 'hooks';
import {
  STATUS_MAP,
  STATUS_TRANSLATIONS,
  USERS_TO_COMMUNITY_FILTERS,
  USER_ORGANISATION_STATUS_MAP,
  USER_ORGANISATION_STATUS_MAP_TRANSLATIONS,
  USER_TYPE_MAP_TRANSLATIONS,
  searchParamsToArray,
} from '../utils';

import {
  STATUS_MAP as COMMUNITY_STATUS_MAP,
  STATUS_TRANSLATIONS as COMMUNITY_STATUS_TRANSLATIONS,
} from '../../Communities/Communities.page';
import { getConsentsFormat } from 'utils/consents';

const UsersToCommunity = () => {
  const [filtersModalOpen, setFiltersModalOpen] = useState(false);

  const { params, setQueryParam, removeQueryParam, setMultipleQueryParams } =
    useQueryParams<{
      userStatus: string;
      communityStatus: string;
      userToCommunityStatus: string;
      roles: string;
      hide_test?: 'true';
    }>();

  const filterBtnRef = useRef<HTMLButtonElement>(null);

  const filterValues = useMemo(
    () => ({
      userStatus: searchParamsToArray(params.userStatus),
      communityStatus: searchParamsToArray(params.communityStatus),
      userToCommunityStatus: searchParamsToArray(params.userToCommunityStatus),
      roles: searchParamsToArray(params.roles),
    }),
    [params]
  );

  const activeFiltersCount = useMemo(
    () =>
      (filterValues.userStatus?.length || 0) +
      (filterValues.communityStatus?.length || 0) +
      (filterValues.userToCommunityStatus?.length || 0) +
      (filterValues.roles?.length || 0),
    [filterValues]
  );

  const translationKeys = useMemo(
    () =>
      ({
        user_id: 'User ID',
        user_firstname: 'Name',
        user_email: 'Email',
        user_status: 'User status',
        user_type: 'User type',
        organisation_id: 'Community ID',
        organisation_name: 'Community Name',
        organisation_status: 'Community Status',
        user_organisation_status: 'User to community status',
        user_consents: 'User Accepted consents',
      } as Record<Partial<keyof UserToCommunity>, string>),
    []
  );

  const renderConsents = useCallback((consents: Array<ConsentType>) => {
    // BE side retrive as some consents for which we dont have description
    // we dont want to show them, so with this const we check if all consents are invalid without description and show empty state
    // and bellow in map function we check for each consent does have description
    const validConsents = consents.some(({ description }) => description);

    return (
      <div className="kinto-users__table__consents">
        {!consents.length || !validConsents ? (
          <small className="kinto-users__table__consents__title">
            There are no accepted consents.
          </small>
        ) : (
          consents.map(
            ({ name, description }) =>
              description && (
                <StatusElement<INameExtended>
                  key={uniqueId(name)}
                  status="canceled"
                  icon="info-circle"
                  enableTooltip
                  tooltipText={description}
                >
                  {name}
                </StatusElement>
              )
          )
        )}
      </div>
    );
  }, []);

  const mapUsersToCommunity = useCallback(
    (users: UserToCommunity[]) =>
      users.map(
        ({
          user_id,
          user_firstname,
          user_lastname,
          user_email,
          user_status,
          user_type,
          organisation_id,
          organisation_name,
          organisation_status,
          user_organisation_status,
          user_consents,
        }: UserToCommunity) =>
          ({
            id: +`${user_id}${organisation_id}`,
            user_id,
            user_firstname:
              !user_firstname || !user_lastname
                ? '-'
                : `${user_firstname} ${user_lastname}`,
            user_email,
            user_status: (
              <StatusElement status={STATUS_MAP[user_status]}>
                {STATUS_TRANSLATIONS[user_status]}
              </StatusElement>
            ),
            user_type: USER_TYPE_MAP_TRANSLATIONS[user_type],
            organisation_id,
            organisation_name,
            organisation_status: (
              <StatusElement
                status={
                  COMMUNITY_STATUS_MAP[organisation_status as CommunityStatus]
                }
              >
                {
                  COMMUNITY_STATUS_TRANSLATIONS[
                    organisation_status as CommunityStatus
                  ]
                }
              </StatusElement>
            ),
            user_organisation_status: (
              <StatusElement
                small
                status={USER_ORGANISATION_STATUS_MAP[user_organisation_status]}
              >
                {
                  USER_ORGANISATION_STATUS_MAP_TRANSLATIONS[
                    user_organisation_status
                  ]
                }
              </StatusElement>
            ),
            user_consents: renderConsents(getConsentsFormat(user_consents)),
          } as any)
      ),
    [renderConsents]
  );

  const {
    data,
    count,
    totalCount,
    totalPages,
    currentPage,
    activeColumnSort,
    setCount,
    setCurrentPage,
    setActiveColumnSort,
  } = useTablePagination<UserToCommunity, 'users'>({
    itemsKey: 'users',
    spinnerParentClass: '.kinto-page',
    deps: [params],
    mappingFunction: (values: Array<UserToCommunity>) =>
      mapUsersToCommunity(values),
    initialParams: { sortBy: 'user_id', sortDirection: 'asc' },
    apiRequest: ({ per_page, currentPage, sort_by, sort_direction }) => {
      return apiUsers.getUsersToCommunity({
        per_page,
        page: currentPage,
        sort_by,
        sort_direction,
        userStatus: params.userStatus,
        organisationStatus: params.communityStatus,
        userOrganisationStatus: params.userToCommunityStatus,
        roles: params.roles,
        hide_test: +!!params.hide_test,
      });
    },
  });

  const handleOnColumnSort = useCallback(
    (sortOptions: SortOption<UserToCommunity>) => {
      setActiveColumnSort(sortOptions);
      setCurrentPage(1);
    },
    [setActiveColumnSort, setCurrentPage]
  );

  const onSubmit = useCallback(
    async ({
      userStatus,
      communityStatus,
      userToCommunityStatus,
      roles,
    }: any) => {
      setFiltersModalOpen(false);
      setCurrentPage(1);
      setMultipleQueryParams({
        userStatus,
        communityStatus,
        userToCommunityStatus,
        roles,
      });
    },
    [setCurrentPage, setMultipleQueryParams]
  );

  return (
    <TablePageLayout.PageLayoutContainer className="kinto-page">
      <Breadcrumbs
        crumbs={[
          { text: 'Users', href: appUri.USERS },
          { text: 'Users to Community', href: appUri.USERS_TO_COMMUNITY },
        ]}
        className="kinto-users-feedback__breadcrumbs"
      />

      <div className="kinto-users-feedback__title">
        <Heading
          level="1"
          color={getColor('--PRIMARY_1_1')}
          className="kinto-page__header"
        >
          Users to Community
        </Heading>
      </div>

      <div className="kinto-page__actions">
        <FiltersButton
          activeFiltersCount={activeFiltersCount}
          onClick={() => setFiltersModalOpen(true)}
          buttonRef={filterBtnRef}
          className='kinto-page__actions__filter-btn'
        />
        <Switch
          className="kinto-page__actions__right-side__toggle"
          label="Hide test users"
          value={params.hide_test === 'true'}
          onChange={() =>
            !params.hide_test
              ? setQueryParam('hide_test', 'true')
              : removeQueryParam('hide_test')
          }
        />
      </div>

      {data.length === 0 ? (
        <div className="kinto-page__empty-state">
          Sorry, there are no results that match your search.
        </div>
      ) : (
        <Table<UserToCommunity>
          tableId="users-to-community-table"
          breakAtMaxWidth={1200}
          tableData={data}
          translationKeys={translationKeys}
          initialSort={activeColumnSort}
          excludeColumns={['id']}
          excludeSortColumns={['user_consents']}
          paginationData={{
            currentPage,
            limit: count,
            totalCount,
            totalPages,
          }}
          goToPageInputProps={{ placeholder: 'Go to page' }}
          onPageChange={setCurrentPage}
          onLimitChange={(data: SelectOption) => {
            setCurrentPage(1);
            setCount(+data.value);
          }}
          onColumnSortClicked={handleOnColumnSort}
        />
      )}

      {filtersModalOpen && (
        <FiltersModal
          onClose={() => setFiltersModalOpen(false)}
          filters={USERS_TO_COMMUNITY_FILTERS}
          onSubmit={onSubmit}
          triggerRef={filterBtnRef.current!}
          initialData={filterValues}
        />
      )}
    </TablePageLayout.PageLayoutContainer>
  );
};

export default UsersToCommunity;
