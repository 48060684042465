import { StatusElementStatus } from '@faxi/web-component-library';
import { AccountStatus, UserOrganisationStatusToCommunity } from 'models/User';
import { maxLength } from 'validation/general';

export const searchParamsToArray = (params?: string) => {
  if (!params) return [];

  return params.split(',').filter((s) => !!s);
};

export const statusFilterToApiParam = (params: string) => {
  const filters = searchParamsToArray(params);

  return filters.length > 1 ? undefined : filters[0];
};

export const USER_TYPE_MAP_TRANSLATIONS = {
  regular_user: 'Regular user',
  admin: 'Admin',
};

export const USER_ORGANISATION_STATUS_MAP = {
  approved: 'approved',
  pending: 'pending',
  rejected: 'rejected',
  deactivated: 'canceled',
  deleted: 'draft',
} as Record<UserOrganisationStatusToCommunity, StatusElementStatus>;

export const USER_ORGANISATION_STATUS_MAP_TRANSLATIONS = {
  approved: 'Approved',
  pending: 'Pending',
  rejected: 'Rejected',
  deactivated: 'Deactivated',
  deleted: 'Deleted',
};

export const STATUS_MAP = {
  active: 'active',
  deactivated: 'canceled',
  deleted: 'rejected',
} as Record<AccountStatus, StatusElementStatus>;

export const STATUS_TRANSLATIONS = {
  active: 'Active',
  deactivated: 'Deactivated',
  deleted: 'Deleted',
} as Record<AccountStatus, string>;

export const deactivateReasonMessage = (name: string, message: string) =>
  `You are not able to delete ${name} while this ${message.toLowerCase()}`;

export const inputValidation = [
  maxLength('Maximum number of characters is 40', 40),
];

export const SEARCH_PARAMS = {
  search: 'search',
} as const;

export const stringToArrayBuffer = (s: string) => {
  const buffer = new ArrayBuffer(s.length);
  const view = new Uint8Array(buffer);
  for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff; //convert to octet
  return buffer;
};

export const USERS_FILTERS = [
  {
    legendLabel: 'Status',
    name: 'status',
    checkboxes: [
      {
        label: 'Active',
        value: 'active',
        id: 'filter_people_status_approved',
      },
      {
        label: 'Deactivated',
        value: 'deactivated',
        id: 'filter_people_status_pending',
      },
    ],
  },
];


export const USERS_TO_COMMUNITY_FILTERS = [
  {
    legendLabel: 'User status',
    name: 'userStatus',
    checkboxes: [
      {
        label: 'Active',
        value: 'active',
      },
      {
        label: 'Deactivated',
        value: 'deactivated',
      },
      {
        label: 'Deleted',
        value: 'deleted',
      },
    ],
  },
  {
    legendLabel: 'Community status',
    name: 'communityStatus',
    checkboxes: [
      {
        label: 'Active',
        value: 'active',
      },
      {
        label: 'Deactivated',
        value: 'deactivated',
      },
      {
        label: 'Hidden',
        value: 'hidden',
      },
      {
        label: 'Deleted',
        value: 'deleted',
      },
    ],
  },
  {
    legendLabel: 'User to community status',
    name: 'userToCommunityStatus',
    checkboxes: [
      {
        label: 'Approved',
        value: 'approved',
      },
      {
        label: 'Pending',
        value: 'pending',
      },
      {
        label: 'Rejected',
        value: 'rejected',
      },
      {
        label: 'Deactivated',
        value: 'deactivated',
      },
      {
        label: 'Deleted',
        value: 'deleted',
      },
    ],
  },
  {
    legendLabel: 'Roles',
    name: 'roles',
    checkboxes: [
      {
        label: 'Admins',
        value: 'admin',
      },
      {
        label: 'Regular users',
        value: 'regular_user',
      },
    ],
  },
];