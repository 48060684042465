import { ForwardedRef } from 'react';
import { ButtonProps, Icon } from '@faxi/web-component-library';
import classNames from 'classnames';

import * as Styled from './FiltersButton.styles';

type FiltersButtonProps = {
  activeFiltersCount: number;
  buttonRef?: ForwardedRef<HTMLButtonElement>;
} & ButtonProps;

const FiltersButton = (props: FiltersButtonProps) => {
  const { className, activeFiltersCount, buttonRef, ...rest } = props;

  return (
    <Styled.FiltersButton
      ref={buttonRef}
      className={classNames(className, {
        'kinto-filter-button--active': activeFiltersCount > 0,
      })}
      {...rest}
    >
      <Icon name="filter" />
      <span>filters</span>
      {activeFiltersCount > 0 && <span>{activeFiltersCount}</span>}
    </Styled.FiltersButton>
  );
};

export default FiltersButton;
